import { useEffect, useState } from 'react'
import data from '../Data/ex_json.json'
import englishMap from '../Data/plain_english_mapper.json'
import Card from './global/Card.js';


const TableOne = ({ user, data }) => {
    if (!user || !data) return;

    let characterDict = {
        "RACE_ALASKAN_INDIAN": "Alaskan Indian",
        "RACE_ASIAN": "Asian",
        "RACE_BLACK": "Black",
        "RACE_MULTI": "Multiple",
        "RACE_OTHER": "Other",
        "RACE_WHITE" : "White", 
        "RACE_HAWAIN_PACIFIC": "Hawaiian Pacific"
    };

    let demographicDict = {
        "AGE_TRANS": "Age",
        "RACE": "Race",
        "SEX": "Sex",
        "BMI_TRANS": "BMI",
        "SMOKE" : "Smoker",
    }

    function formatComorbidityLabel(label) {
        if (label.includes('HX')) return label.replace('HX', '');
        else if (label === "RENAFAIL") return "Renal Failure"
        return label.charAt(0) + label.substring(1).toLowerCase()
    }

    return (
        <Card style={{ display: 'flex', flexDirection: 'column', width: '900px', fontSize: '12px', maxHeight: '1000px' }}>
            <div style={{ fontSize: '16px', marginBottom: '30px'}}><span style={{ fontWeight: 'bold' }}>Table 1.</span> Characteristics Of Patient Population</div>
            {/*Headers*/}
            <div style={{ display:'flex', flex: 1, flexDirection: 'row', fontSize: '16px', fontWeight: 700, padding:'1vh' }}>
                <div style={{display:'flex', flex:1, flexDirection: 'column'}}>Characteristic</div>
                <div style={{display:'flex', flex:1, flexDirection: 'column'}}>
                    Illinois Masonic
                    <br/>
                    Medical Center
                </div>
                <div style={{display:'flex', flex:1, flexDirection: 'column'}}>Other Hospitals</div>
                <div style={{display:'flex', flex:.3, flexDirection: 'column', transform: 'translateX(10px)'}}>P-Value</div>
            </div> {/* End Headers*/}
            
            {/*Row 1*/}
            <div style={{ marginTop: '10px' }}>
                {data["Total number of patients"]?.map((key, i) => (
                    <div key={i} style={{display:'flex', flex: 1, flexDirection: 'row', padding:'1vh'}}>      
                        <div style={{ display:'flex', flex:1, flexDirection: 'column', fontWeight: 'bold', fontSize: '14px'}}>{key["Feature"] === 'n'? 'Total Number Of Patients': 'n'}</div>
                        <div style={{ display:'flex', flex:1, flexDirection: 'column'}}>{key[user.identifier]}</div>
                        <div style={{ display:'flex', flex:1, flexDirection: 'column'}}>{key["Department"]}</div>
                        <div style={{ display:'flex', flex:.3, flexDirection: 'column'}}>{key["P-Value"]}</div>
                    </div> 
                ))}{/*End Row 1*/}
            </div>
            
            {/*Row 2*/}
            <div style={{display:'flex', flex:1, flexDirection: 'column', padding:'1vh', marginTop: '10px' }}>
                <span style={{ fontWeight: 'bold', fontSize: '14px'}}>Demographics    (N, %)</span>
                {data["Demographics (N, %)"]?.map((key, i) => (
                    <div key={i} style={{ display: 'flex', flexDirection: 'column' }}>
                         {key["Feature"] in demographicDict ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'baseline' }}>
                                    <div style={{ fontSize: '14px', fontWeight: 'bold', marginTop: '10px', marginLeft: '10px'}}>{demographicDict[key["Feature"]]}</div>
                                    <div style={{ paddingRight: '40px', transform: (key["Feature"] === "RACE" || key["Feature"] ==="SEX" || key["Feature"] === "BMI_TRANS" || key["Feature"] === "SMOKE" || key["Feature"] === "AGE_TRANS" ) ? 'translateX(10px)' : (key["Feature"] === "AGE_TRANS") ? 'translateX(20px)' : null }}>{key['P-Value']}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1}}>
                                    <div style={{ display:'flex', flex:1, flexDirection: 'column',  marginTop:'.8vh' }}>
                                        <div style={{ marginLeft:'2vw'}}>{key[""] in characterDict ? characterDict[key[""]] : key[""] }</div>
                                    </div>
                                    <div style={{ display:'flex', flex:1, flexDirection: 'column',  justifyContent:'flex-end'}}>
                                        {key[user.identifier] || '0 (0.0)'}
                                    </div>
                                    <div style={{ display:'flex', flex:1, flexDirection: 'column', justifyContent:'flex-end' }}>
                                        {key["Department"] || '0 (0.0)'}
                                    </div>
                                    <div style={{ display:'flex', flex:.3, flexDirection: 'column', justifyContent:'flex-end' }}></div>     
                                </div>         
                            </div>
                        :
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1}}>
                            <div style={{ display:'flex', flex:1, flexDirection: 'column',  marginTop:'.8vh' }}>
                                {key["Feature"] in demographicDict ? 
                                    <div style={{ alignSelf: 'stretch', marginLeft:'2vw',  fontWeight: 'bold', marginLeft: '10px', marginTop: '20px',  marginBottom: '10px'}}>{demographicDict[key["Feature"]]}</div>
                                : null}
                                <div style={{ marginLeft:'2vw'}}>{key[""] in characterDict ? characterDict[key[""]] : key[""] }</div>
                            </div>
                            <div style={{ display:'flex', flex:1, flexDirection: 'column',  justifyContent:'flex-end'}}>
                                {key[user.identifier] || '0 (0.0)'}
                            </div>
                            <div style={{ display:'flex', flex:1, flexDirection: 'column', justifyContent:'flex-end' }}>
                                {key["Department"] || '0 (0.0)'}
                            </div>

                            <div style={{ display:'flex', flex:.3, flexDirection: 'column', justifyContent:'flex-end' }}>
                            </div>     
                        </div>                   
                        }
                    </div>
                ))}
            </div>{/*End Row 2*/}

            {/*Row 3*/}
            <div style={{display:'flex', flex:1, flexDirection: 'column', fontWeight: 700 , padding:'1vh', fontSize: '14px'}}>Comorbidities      (N, %)</div>
            {data["Comorbidities (N, %)"]?.map((key, i) => (
                <div key={i} style={{display:'flex', flex: 1, flexDirection: 'row',  padding:'.25vh'}}>
                    <div style={{display:'flex', flex:1, flexDirection: 'column'}}>
                        <div style={{marginLeft:'2vw'}}>{formatComorbidityLabel(key["Feature"])}</div>
                    </div>
                    <div style={{display:'flex', flex:1, flexDirection: 'column'}}>
                        {key[user.identifier] ? key[user.identifier] : '0 (0.0)'}
                    </div>
                    <div style={{display:'flex', flex:1, flexDirection: 'column'}}>
                        {key["Department"] ? key["Department"] : '0 (0.0)'}
                    </div>
                    <div style={{display:'flex', flex:.3, flexDirection: 'column'}}>
                        {key["P-Value"] ? key["P-Value"] : ''}
                    </div>
                </div> 
            ))}{/*Row 3*/}
            
        </Card>
    )
}

export default TableOne