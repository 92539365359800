import React, { useEffect, useState } from 'react'
import logo from '../Images/logo_white.png' 
import {Link, Navigate, useNavigate} from 'react-router-dom'

const Login = () => {

    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');

    const [success, setSuccess] = useState(true);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if(email === 'demo@kelahealth.com' && pwd === 'passwordPassword') {
            localStorage.setItem('is_logged_in', true);
            navigate("/UseCase")
        }
        else
        { 
            setSuccess(false);
            setTimeout(() => {setSuccess(true) }, 3000);
        }
       
    }

  return (
    <div style={styles.background}>
        <div>
        <form style={styles.form} onSubmit={handleSubmit}>
            <div style={styles.topBlueMargin}>
                <img src={logo} style={{ width: '250px', marginLeft: '22px', marginTop: '2rem'}}></img>
                <div style={{ color: 'white', fontStyle: 'italic', fontSize: '16px', paddingTop: '.3rem', marginLeft: '22px' }}>Demo Hub</div>
            </div>
            <div style={styles.inputArea}>
                Sign in with your email and password
                <div className='form'>
                    <div style={styles.inputAreaLabel}><label htmlFor="email">Email</label></div>
                    <input style={styles.inputAreaInput}
                        type="text" 
                        placeholder="name@host.com" 
                        name="email" 
                        id="email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    >    
                    </input>
                </div>
                <div className='form'>
                    <div style={styles.inputAreaLabel}><label htmlFor="password">Password</label></div>
                    <input style={styles.inputAreaInput}
                        type="password" 
                        placeholder="Password" 
                        name="password" 
                        id="password"
                        value={pwd}
                        onChange={(e) => setPwd(e.target.value)}
                        required
                    >
                    </input>
                </div>
                <div style={{ paddingTop: '.5rem', paddingBottom: '.75rem'}}>
                    <a style={{ color: '#0367B4', fontSize: '14px', fontWeight: '700', textDecoration:'none'}} href="#">Forgot your password?</a>
                </div>
                
                <div style={styles.submit} >
                    <button style={styles.submitButton} onClick={handleSubmit}>Sign In</button>
                </div>
            </div>
            {!success && <div style={styles.loginAlert}>
               <div>Incorrect username or password.</div>
           </div>}

        </form>
        </div>

        
    </div>
  )
}

const styles = {
    background: {
        display: 'flex',
        flex: 1,
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#EBEBEB',
        font: 'Roboto'
    },
    topBlueMargin: { 
      display: 'flex', 
      flexDirection: 'column', 
      backgroundColor: '#0367B4',
      height: '106px',
      justifyContent: 'center', 
      alignItems: 'flex-start',
      borderRadiusTop: '10px',
      paddingBottom: '30px',
      width: '351px',
    },
    inputArea: { 
        background: '#FFFFFF',
        display:'flex',
        flexDirection: 'column',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingTop: '2rem',
        paddingBottom: '1rem',
    },
    inputAreaLabel: {
        display:'block',
        color: '#333333',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '700',
        paddingTop: '.5rem',
        paddingBottom: '.5rem',
    },
    inputAreaInput: {
        width: '95%',
        height:'5vh',
        fontSize: '16px',
        border:' 1px solid #CED4DA',
        borderRadius: '4px',
        color: '#6C757D',
        textIndent: '1.5vh',
    },
    dropdown: {
      width: '95%',
      marginTop: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputLable: {
      display: 'block',
      color: '#333333',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '700',
      paddingTop: '.5rem',
      paddingBottom: '.5rem'
    },
    submitButton: {
        background:' #0367B4',
        borderRadius: '4.8px',
        color: 'white',
        height: '40px',
        width: '100%',
        cursor: 'pointer',
        border: 'none',
    },
    submit: {
        width: '98%',
        background: 'white',
        cursor: 'pointer',
        paddingTop: '1rem',
        paddingBottom: '1.3rem',
    },
    welcome: {
        fontWeight: '700',
        fontSize: '38px',
        color: '#333333',
        paddingLeft: '25px',
        paddingTop: '25px'
    },
    useCase: {
        fontWeight: '700',
        fontSize: '24px',
        color: '#505050',
        paddingLeft: '25px'
    },
    loginAlert: {
        display:' inline-block',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center',
        borderRadius: '4px',
        padding: '1vh',
        background: '#CCE5FF',
        border: '1px solid #B8DAFF',
        color: '#004085',
        transition: 'opacity 0.5s',
        
        width: '100%'
    },
    form: {
        display:'flex',
        flexDirection: 'column',
        width: '351px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
    }
  }

export default Login