import React from 'react';
import './App.css';
import Usage from './pages/Usage';
import Surgeries from './pages/Surgeries';
import RiskPredictions from './pages/RiskPredictions';
import RiskFactors from './pages/RiskFactors';
import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { RequireAuth } from './RequireAuth';
import { AuthProvider } from './useAuth';

//npx json-server --watch data/patient_factors.json --port 8001  
//npx json-server --watch data/patientdash.json --port 8000
//npx json-server --watch data/complete_patient_factors.json --port 8000

function App() {


  return (
    <Usage />
    
)};


export default App;
