import { useEffect, useState } from 'react';
import Card from './global/Card.js';
import englishMap from '../Data/plain_english_mapper.json'
import * as formatData from '../Data/formatting.js';
import Plot from 'react-plotly.js';


const dropdownOptions = {
  outcome: [
    { value: 'optime', label: 'Operation Time' },
    { value: 'discharge_disposition', label: 'Home Discharge' }
  ],
  approach: [
    { value: 'robotic', label: 'Robotic' },
    { value: 'laparoscopic', label: 'Laparoscopic' },
    { value: 'open', label: 'Open' },
    { value: 'all', label: 'All' }
  ]
};

export default function({ user = {}, data = {} }) {
  const [riskFactorsSurgeon, setRiskFactorsSurgeon] = useState(null);
  const [riskFactorsDepartment, setRiskFactorsDepartment] = useState(null);


  useEffect(() => {
    if (!data.surgeon || !data.department) return;
    setRiskFactorsSurgeon(formatData.contributingRiskFactors(data.surgeon));
    setRiskFactorsDepartment(formatData.contributingRiskFactors(data.department));
  }, [data]);

  return (
    <Card style={styles.container}>
      <div style={{ fontSize: '16px', marginBottom: '30px', alignSelf: 'flex-start', fontWeight: 'bold' }}>Contributing Risk Factors by Surgical Approach</div>
      <div style={{ display:'flex', flexDirection: 'row', marginLeft: '50px', marginRight: '40px' }}>
        <ChartWithSelections subject={user.name} data={riskFactorsSurgeon} style={{transform: 'translateX(40px)'}}/>
        <ChartWithSelections subject={'Department'} data={riskFactorsDepartment} style={{transform: 'translateX(-40px)'}}/> 
      </div>
    </Card>
  );
}


function ChartWithSelections({ subject, data, style}) {
  // Dropdown values
  const [selectedOutcome, setSelectionOutcome] = useState(dropdownOptions.outcome[0].value);
  const [selectedApproach, setSelectionApproach] = useState(dropdownOptions.approach[0].value);
  // Chart series labels & values
  const [decreasingRiskFactors, setDecreasingRiskFactors] = useState([]);
  const [increasingRiskFactors, setIncreasingRiskFactors] = useState([]);
  const [dynamicAxis, setDynamicAxis] = useState(.5);

  // Set risk factor data
  useEffect(() => {
    if (!data) return;
    const series = data?.[selectedOutcome]?.[selectedApproach];
    if (!series) return console.log('Error unable to resolve risk factor data ', data?.[selectedOutcome]); // skip if data hasn't populated yet
    const weightedFactors = series.sort((a, b) => a.value > b.value ? -1 : 1)
      .reduce((acc, { key, value }) => {
        if (Math.abs(acc.greatestMagnitude) < value) acc.greatestMagnitude = value
        if (value < 0) acc.negativeWeights.push({ key, value: parseFloat(value.toFixed(3)) });
        else acc.positiveWeights.push({ key, value: parseFloat(value.toFixed(3)) });
        return acc;
      }, { greatestMagnitude: 0, negativeWeights: [], positiveWeights: []});
    /* ^ 
      weightedFactors = {
        greatestMagnitude: .86,
        negativeWeights: [ {key: 'factorname', value: -.188232 }, ...],
        positiveWeights: [ {key: 'factorname', value: .0843 }, ...] 
      }
    */
    setDynamicAxis(weightedFactors.greatestMagnitude);
    setDecreasingRiskFactors(weightedFactors.negativeWeights);
    setIncreasingRiskFactors(weightedFactors.positiveWeights);
  }, [selectedOutcome, selectedApproach, data]);

  function getxAxisDec(){
    let xAxisDec = []
    decreasingRiskFactors.map((key, value) => xAxisDec.push(key["value"]))
    xAxisDec.sort().reverse()
    return xAxisDec.slice(0,5).reverse()
  }
  function getxAxisInc(){
    let xAxisInc = []
    increasingRiskFactors.map((key, value) => xAxisInc.push(key["value"]))
    xAxisInc.sort().reverse()
    return xAxisInc.slice(0, 5).reverse()
  }
  function getyAxisDec(){
    let yAxisDec = new Map()
    decreasingRiskFactors.map((key, value) => {
      yAxisDec.set(key["key"], key["value"])})
    yAxisDec = new Map([...yAxisDec].sort((a, b) => a[1] - b[1]));
    let getyAxisDecLabels = [...yAxisDec.keys()].slice(0,5).reverse()
    return translateLabels(getyAxisDecLabels)
  }
  function getyAxisInc(){
    let yAxisInc = new Map()
    increasingRiskFactors.map((key, value) => {
      yAxisInc.set(key["key"], key["value"])})
    yAxisInc = new Map([...yAxisInc].sort((a, b) => b[1] - a[1]));
    let getyAxisIncLabels = [...yAxisInc.keys()].slice(0,5)
    return translateLabels(getyAxisIncLabels.reverse())
  }

  function translateLabels(labels) {
    let newLabels = []
    for (var i = 0; i < labels.length; i++) {
      newLabels.push(englishMap[labels[i]]?englishMap[labels[i]] : labels[i].charAt(0) + labels[i].substring(1).toLowerCase() )
    }
    return newLabels
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '50px', ...style}}>

      {/* DROPDOWN SECTION */}
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', paddingRight :'50px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px', fontSize: '16px', fontWeight: 700 }}>{subject === 'Department' ? 'Other Hospitals' : subject}</div>
        {/* OUTCOME DROPDOWN */}
        <select style={styles.select} value={selectedOutcome} onChange={({ target }) => setSelectionOutcome(target.value)}>
          {dropdownOptions.outcome.map(({ value, label }, i) => <option key={i} value={value}>{label}</option>)}
        </select>  {/* END OUTCOME DROPDOWN */}
        {/* APPROACH DROPDOWN */}
        <select style={styles.select} value={selectedApproach} onChange={({ target }) => setSelectionApproach(target.value)}>
          {dropdownOptions.approach
            // .filter(({ value }) => {
            //   if (subject !== 'Department') return true;
            //   if (value === 'all') return false
            //   return true; 
            // })
            .map(({ value, label }, i) => <option key={i} value={value}>{label}</option>)}
        </select>  {/* END APPROACH DROPDOWN */}
      </div> {/* END DROPDOWN SECTION */}

      <Plot
        data={[
          {
              name: "Decreases Risk",
              x: getxAxisDec(),
              y: getyAxisDec(),
              type: 'bar',
              orientation: 'h',
              align:'left',
              marker: {color: "rgba(74, 142, 195, 1)", width: 1},
          },
          {
              name: "Increases Risk",
              x: getxAxisInc(),
              y: getyAxisInc(),
              type: 'bar',
              orientation: 'h',
              textposition: 'auto',
              align: 'right',
              marker: {color: "rgba(255, 154, 19, 1)", width: 1},
          },
        ]}
        layout={{
          height: 350,
          width: 400,
          hovermode:false, 
          font: {
            size:8,
            color: '#000000',
          },  
          xaxis: {
            automargin: true,
            range: [dynamicAxis * -1, dynamicAxis]
          },
          yaxis: {
            automargin:'left',
          },
          showlegend:true, 
          margin:{pad: 15},
        }}
      /> 
    </div>
  )
}

const styles = {
  container: { 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '12px',
    width: '900px',
    fontWeight: 500,
    maxWidth: '1250px',
    backgroundColor: 'white',
    marginBottom: '50px'
  },
  select: {
    backgroundColor: '#f2f2f2',
    marginBottom: '20px',
    fontWeight: 'bold',
    height: '30px',
    width: '250px',
    borderRadius: '5px'
  }
}


