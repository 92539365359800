export default function ({ children, style }) {
  return (
    <div style={{ ...styles.container, ...style}}>
      {children}
    </div>
  )
}

const styles = {
  container: {
    backgroundColor: '#f7f7f7',
    width: '1000px',
    padding: '60px',
    paddingBottom: '60px',
    borderRadius: '10px',
    boxShadow: '2px 5px 5px grey'
  }
}