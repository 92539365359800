import React, { createContext, useContext, useState } from "react";
import {useNavigate } from "react-router-dom";

const authContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState({});
  const [drName, setDrName] = useState('');
  const navigate = useNavigate();

  const login = (userDetails) => {
    if(userDetails.email === "demo@kela.com" && userDetails.pwd === "passwordPassword") {
        setUser(userDetails);
        setDrName("Doctor");
        localStorage.setItem('is_logged_in', true);
        navigate("/UseCase")
    }
  };

  const logout = () => {
    setUser({});
    navigate("/");
  };

  return (
    <authContext.Provider value={{ user, drName, login, logout }}>
      {children}
    </authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
};


