import { useState } from 'react';
import logoWhite from '../assets/logo-white.png';

export default function({ logout }) {
  const [isLoggedIn, toggleLoginState] = useState(false);
  const [isLoginError, toggleLoginError] = useState(false);
  const [isDataReady, toggleDataReady] = useState(false);

  return (
    <div style={styles.container}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <img src={logoWhite} style={{ height: '30px', width: 'auto' }} alt="Logo" />
        <div onClick={logout} style={styles.logout}><span>Logout</span></div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    zIndex: 2,
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    height: '50px', 
    background: 'linear-gradient(90deg, rgba(4,100,180,1) 3%, rgba(16,140,235,1) 80%, rgba(36,156,244,1) 100%)',
    boxShadow: '0px 2px 4px #9c9c9c',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    paddingTop: '10px',
  },
  logout: { 
    color: 'white', 
    fontWeight: 700,
    marginRight: '20px',
    cursor: 'pointer',
    padding: '10px',
    paddingTop: '5px',
    paddingBottom: '6px',
    border: '1px solid white',
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center'
  }
}