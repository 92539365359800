import { useState, useEffect } from 'react';
import Card from './global/Card.js';


export default function ({ data, user }) {
  const defaultData = {
      n: {},
      home: {},
      home_health: {},
      snf: {},
      expired: {},
      other: {},
      length_of_stay_days_mean_sd: {},
      operation_time_mean_sd: {}
  }
  const [surgeonData, setSurgeonData] = useState(defaultData);
  const [departmentData, setDepartmentData] = useState(defaultData);
  const [pValueData, setPValueData] = useState({});

  useEffect(() => {
    if (!data) return;
    const surgeonObj = data[user.identifier].reduce((acc, member) => {
      let keyName = formatKeyName((member[""]|| member.Feature))
      if (keyName === "") keyName = 'number_patients';
      acc[keyName] = {
        robotics: member.Robotic, 
        lap: member.Laparoscopic, 
        open: member.Open
      };
      return acc;
    }, {});
    const departmentObj = data.DEPARTMENT.reduce((acc, member) => {
      let keyName = formatKeyName((member[""]|| member.Feature))
      if (keyName === "") keyName = 'number_patients';
      acc[keyName] = {
        robotics: member.Robotic, 
        lap: member.Laparoscopic, 
        open: member.Open
      };
      return acc;
    }, {});
    const pValues = data.DEPARTMENT.reduce((acc, member) => {
      if (!member.Feature || member.Feature === 'n') return acc;
      const keyName = formatKeyName(member.Feature)
      acc[keyName] = member['P-Value'];
      return acc;
    }, {});
    setSurgeonData(surgeonObj);
    setDepartmentData(departmentObj);
    setPValueData(pValues);
  }, [data]);

  function formatKeyName(key) {
    if (!key) return key;
    return key.toLowerCase()
      .replaceAll(' ', '_')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll(',', '')
  }

  function checkMin(keyname, def) {
    if(keyname === 'operation_time_mean_sd') {
      let num = parseFloat(def)
      return Math.round(num)
    }
    else if (keyname === 'length_of_stay_days_mean_sd' && def) {
      return (def || '').split(' (')[0];
    }
    else return def
  }
  /*
      {
        "discharge_disposition_n_%": "0.379",
        "length_of_stay_days_mean_sd": "0.254",
        "operation_time_mean_sd": "<0.001"
      }
  */

  const methodMap = [
    { label: 'Robotics', keyName: 'robotics' },
    { label: 'Laparoscopic', keyName: 'lap' },
    { label: 'Open', keyName: 'open' }
  ]

  const outcomeMap = [
    { label: 'Number Of Patients', keyName: 'n' },
    { label: 'Avg Length Of Stay (Days)', keyName: 'length_of_stay_days_mean_sd' },
    { label: 'Avg Operation Time (Minutes)', keyName: 'operation_time_mean_sd' },
    { label: 'Discharge Disposition (N, %)', keyName: 'discharge_disposition_n_%', parent: true },
    { label: 'Home', keyName: 'home', child: true },
    { label: 'Home Health', keyName: 'home_health', child: true },
    { label: 'SNF', keyName: 'snf', child: true },
    { label: 'Expired', keyName: 'expired', child: true },
    { label: 'Other', keyName: 'other', child: true }
  ];

  const pValueColumFlex = .3;
  const cellPadding = '10px';
  const cellWidth = '85px';
  const cellHeight = '28px';

  return (
    <Card style={{ display: 'flex', flexDirection: 'column', width: '900px', fontSize: '12px' }}>
        <div style={{ fontSize: '16px', marginBottom: '30px'}}><span style={{ fontWeight: 'bold' }}>Table 2.</span> Outcomes</div>
      
      <div style={{ ...styles.flexRow, border: '2px solid #787878', borderRadius: '10px' }}>

        <div style={{ flex: 2, display: 'flex', flexDirection: 'column', borderRight: '2px solid #787878'}}>
          <div style={{ height: cellHeight }}></div>
          <div style={{ display: 'flex', height: cellHeight, fontWeight: 'bold', fontSize: '14px', borderBottom: '2px solid #787878', marginBottom: '15px', alignItems: 'center', paddingLeft: cellPadding }}>Outcome</div>
          {outcomeMap.map(({ label, child }, i) => (
            <div key={i} style={{ height: cellHeight, fontWeight: 'bold', fontSize: '11px', paddingLeft: child ? '30px' : cellPadding }}>{label}</div>
          ))}
        </div>

        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', borderRight: '2px solid #787878' }}>
          <div style={{ display: 'flex', height: cellHeight, fontWeight: 'bold', fontSize: '14px', justifyContent: 'center', alignItems: 'center'}}><span>{user.name}</span></div>
          <div style={{ display: 'flex', height: cellHeight, flex: 1 }}>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignSelf: 'stretch' }}>
              <div style={{ display: 'flex', height: cellHeight, fontWeight: 'bold', fontSize: '12px', borderBottom: '2px solid #787878', marginBottom: '15px',alignItems: 'center', paddingLeft: cellPadding }}>Robotics</div>
              {outcomeMap.map(({ keyName, parent }, i) => (
                <div key={i} style={{ width: cellWidth, height: cellHeight, paddingLeft: cellPadding }}>{parent ? '' : checkMin(keyName, surgeonData[keyName]?.robotics) || '0'}</div>
              ))}
            </div>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignSelf: 'stretch' }}>
              <div style={{ display: 'flex', height: cellHeight, fontWeight: 'bold', fontSize: '12px', borderBottom: '2px solid #787878', marginBottom: '15px', alignItems: 'center', paddingLeft: cellPadding }}>Laparoscopic</div>
              {outcomeMap.map(({ keyName, parent }, i) => (
                <div key={i} style={{ width: cellWidth, height: cellHeight, paddingLeft: cellPadding }}>{parent ? '' : checkMin(keyName, surgeonData[keyName]?.lap) || '0'}</div>
              ))}
            </div>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignSelf: 'stretch' }}>
              <div style={{ display: 'flex', height: cellHeight, fontWeight: 'bold', fontSize: '12px', borderBottom: '2px solid #787878', marginBottom: '15px', alignItems: 'center', paddingLeft: cellPadding }}>Open</div>
              {outcomeMap.map(({ keyName, parent }, i) => (
                <div key={i} style={{ width: cellWidth, height: cellHeight, paddingLeft: cellPadding }}>{parent ? '' : checkMin(keyName, surgeonData[keyName]?.open) || '0'}</div>
              ))}
            </div>
          </div>
        </div>

        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', borderRight: '2px solid #787878' }}>
        <div style={{ display: 'flex', height: cellHeight, fontWeight: 'bold', fontSize: '14px', justifyContent: 'center', alignItems: 'center'}}><span>Other Hosptials</span></div>
          <div style={{ display: 'flex', height: cellHeight, flex: 1 }}>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignSelf: 'stretch' }}>
              <div style={{ display: 'flex', height: cellHeight, fontWeight: 'bold', fontSize: '12px', borderBottom: '2px solid #787878', marginBottom: '15px', alignItems: 'center', paddingLeft: cellPadding }}>Robotics</div>
              {outcomeMap.map(({ keyName, parent }, i) => (
                <div key={i} style={{ width: cellWidth, height: cellHeight, paddingLeft: cellPadding }}>{parent ? '' : checkMin(keyName, departmentData[keyName]?.robotics) || '0'}</div>
              ))}
            </div>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignSelf: 'stretch' }}>
              <div style={{ display: 'flex', height: cellHeight, fontWeight: 'bold', fontSize: '12px', borderBottom: '2px solid #787878', marginBottom: '15px', alignItems: 'center', paddingLeft: cellPadding }}>Laparoscopic</div>
              {outcomeMap.map(({ keyName, parent }, i) => (
                <div key={i} style={{ width: cellWidth, height: cellHeight, paddingLeft: cellPadding }}>{parent ? '' :  checkMin(keyName, departmentData[keyName]?.lap) || '0'}</div>
              ))}
            </div>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignSelf: 'stretch' }}>
              <div style={{ display: 'flex', height: cellHeight, fontWeight: 'bold', fontSize: '12px', borderBottom: '2px solid #787878', marginBottom: '15px', alignItems: 'center', paddingLeft: cellPadding }}>Open</div>
              {outcomeMap.map(({ keyName, parent }, i) => (
               
                <div key={i} style={{ width: cellWidth, height: cellHeight, paddingLeft: cellPadding }}>{parent ? '' : checkMin(keyName, departmentData[keyName]?.open) || '0'}</div>
              ))}
            </div>
          </div>
        </div>

        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <div style={{ height: cellHeight }}></div>
          <div style={{ display: 'flex', height: cellHeight, fontWeight: 'bold', fontSize: '12px', borderBottom: '2px solid #787878', marginBottom: '15px', alignItems: 'center', paddingLeft: cellPadding  }}>P-Value</div>
          {outcomeMap.map(({ label, keyName }, i) => (
            <div key={i} style={{ width: cellWidth, height: cellHeight, paddingLeft: cellPadding }}>{pValueData[keyName]}</div>
          ))}
        </div>

      </div>
    </Card>
  )
}

const styles = {
    flexRow : {
        display: 'flex',
        flex: 1,
        flexDirection: 'row'
    },
    flexColumn : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    }
}
