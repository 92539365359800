import React from 'react';
import Plot from 'react-plotly.js';
import Card from './global/Card';



export default function RadarPlot({ data, name }) {
    if (!data) return;
    let maxWeight = 0
    
    getDataSets()

    
    function parseSurgeon(surgeon) {
      const hospitalMap = {
        HOSPITAL_0: 'Illinois Masonic Medical Center',
        HOSPITAL_1: 'Christ Medical Center',
        HOSPITAL_2: 'Lutheran General Hospital'
      };
      return hospitalMap[surgeon]
    }

    
    function getDataSets() {
        const fullDoctorData = []
        const currentLabel = []

        for (const [key1, value1] of Object.entries(data)) {
            const currentData = []
            for (const [key2, value2] of Object.entries(data[key1])) {
                currentData.push(value2 * 100)
                if(value2 * 100 > maxWeight) {
                  maxWeight = value2 * 100
                }
                currentLabel.push(key2)
            }

            let currentDataSet = {
              type: 'scatterpolar',
              r: currentData,
              theta: currentLabel,
              fill: 'toself',
              name: parseSurgeon(key1),
            }
            

            fullDoctorData.push(currentDataSet)
            
        }

        
        return fullDoctorData

    }
    
    const layout = {
      width: 600,
      height: 600,
      xaxis: {
        gridwidth: 5,
      },
      polar: {
        radialaxis: {
          visible: true,
          range: [0, maxWeight],
        },
        gridshape: 'circular'
      },
      paper_bgcolor:"#FFF3",
      showlegend: true,
      legend: {
        x: -10,
        y: 1
      },
      font: {
        size: 10
      }
    }

  return (
    <Card style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', width:'900px' }}>
      <div style={{ fontSize: '16px',  alignSelf: 'flex-start', fontWeight: 'bold'}}>Comorbidity Radar Distribution (%)</div>
      <div style={{ transform: 'translateX(-25px)'}}>
        <Plot data={getDataSets()} layout={layout} />
      </div>
    </Card>
  )
  
  
}
