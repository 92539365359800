export const MenuItems = [
    {
        /*Logo*/
        url: '/UseCase',
        cName: 'nav-links'
    },
    {
        title: 'Surgeries Today',
        url: '/Usage',
        state: 'General_Surgery',
        cName: 'nav-links',
        image: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-calendar-week" viewBox="0 0 16 16">
                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                </svg>
    },
    {
        title: 'Notes',
        url: '#',
        cName: 'nav-links',
        image: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-file-text" viewBox="0 0 16 16">
                    <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                </svg> 
    },
    {
        title: 'Labs',
        url: '#',
        cName: 'nav-links',
        image: <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.47111 13.4443C6.74845 15.6752 5 18.2709 5 19.1933C5 20.3945 6.2599 20.4588 6.51692 20.4588H17.1099C17.4699 20.4588 18.6269 20.3516 18.6269 19.1933C18.6269 18.2709 16.8786 15.6967 15.1557 13.4443H8.47129H8.47111Z" fill="white"/>
                    <path d="M15.6303 8.37137V1.356H16.8219V0.361816H7.25589V1.356H8.44751V8.34297C8.44751 8.34297 0.072998 18.3406 0.072998 21.3512C0.072998 24.3619 3.68147 24.3618 3.68147 24.3618H20.4645C20.4645 24.3618 24.073 24.3618 24.073 21.3512C24.073 18.3404 15.6304 8.37137 15.6304 8.37137H15.6303ZM20.4304 23.3678H3.6473C3.23886 23.3678 1.26437 23.2826 1.26437 21.3797C1.26437 19.9312 4.056 15.8128 6.77923 12.2625L7.15371 11.7797C7.9708 10.7572 8.75372 9.76306 9.43458 8.93949L9.6388 8.68392V8.37148L9.63903 1.35611H14.4391V8.6555L14.6433 8.91108C15.3241 9.73464 16.1071 10.7005 16.9242 11.7513L17.2987 12.2341C20.0222 15.7844 22.8135 19.9027 22.8135 21.3513C22.8135 23.1973 20.9751 23.3677 20.4306 23.3677L20.4304 23.3678Z" fill="white"/>
                </svg>
    },
    {
        title: 'Messages',
        url: '#',
        cName: 'nav-links',
        image: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-left-text" viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                </svg>
    },
    {
        title: 'Profile',
        url: '#',
        cName: 'nav-links',
        image: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                </svg>
    },
]