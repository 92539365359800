import React, { useEffect } from 'react'
import './Home.css'
import { useState } from 'react';
import TableOne from '../Components/TableOne.js';
import RadarPlot from '../Components/RadarPlot.js';
import TableTwo from '../Components/TableTwo.js';
import Header from '../Components/Header.js';
import DownloadPDF from '../Components/DownloadPDF.js';
import ContributingRiskFactors from '../Components/ContributingRiskFactors';
import json from '../Data/ex_json.json';



const Home = ({ user, logout }) => {
    const pdfRef1 = React.useRef();
    const pdfRef2 = React.useRef();
    const [data, setData] = useState(null);

    useEffect(() => {
        try {
            console.log(user.identifier)
            const dataSet = json[user.identifier];
            if (!dataSet) throw Error(`Unable to find dataset for user`);
            setData(dataSet);
        } catch(err) {
            console.log('Issue occurred, logging out', err);
            logout();
        }
    }, [user]);

    if (!data) return;
    return (
        <>
            <Header logout={logout}/>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'scroll' }}>
                <div style={{ margin: '20px'}}></div>
                <div ref={pdfRef1} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'scroll' }}>
                    <TableOne user={user} data={data.TABLE_ONE.DEPARTMENT}/>
                    <div style={{ margin: '20px'}}></div>
                    <RadarPlot 
                        data={json.RADAR_PLOT}/>
                    <div style={{ margin: '20px'}}></div>
                </div>
                <div ref={pdfRef2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'scroll' }}>
                    <TableTwo data={data?.TABLE_TWO} user={user}/>
                    <div style={{ margin: '20px'}}></div>
                    <ContributingRiskFactors user={user} data={{ 
                        surgeon: data?.CONTRIBUTING_FACTORS,
                        department: json.DEPARTMENT.CONTRIBUTING_FACTORS
                    }} />
                    <div style={{ margin: '20px'}}></div>
                </div>
            </div>
            <DownloadPDF imageRefs={[pdfRef1, pdfRef2]} />
        </>
    )
}

export default Home