import React, {useState, useEffect} from 'react'
import { useParams, useLocation } from 'react-router-dom'
import Foldernav from '../components/Foldernav/Foldernav'
import Navbar from '../components/Navbar/Navbar'
import Kelacard from '../components/Kelacard/Kelacard'
import Recommendation from '../components/Recommendations/Recommendation'
import './RiskPredictions.css'
import db from '../../data/db.json';

const RiskPredictions = (props) => { /*When in real practice, will look up the corresponding JSON based on name/mrn*/
    const { patientnamemrn } = useParams();
    const [name, mrn] = patientnamemrn.split("_");
    const ROOT = "https://kelaserver.herokuapp.com/";
    //const ROOT = 'http://localhost:8000/'

    const [chartData, setChartData] = useState({});
    
    useEffect(() => {

      const fetchItems = async () => {
          try{
              // const response = await fetch(ROOT + props.useType);
              // const data = await response.json();
              setChartData(db[props.useType]);
              
          } catch (err) {
              console.log("Error loading single patient info.");
  
          }
      }
  
      fetchItems();
      
    },[]);

    var patientData = [];
    for(var i = 0; i < chartData.length; i++) {
      if(typeof chartData[i][mrn] !== 'undefined') {
        patientData = chartData[i][mrn];
      }
    }

    var bleedlev = patientData["Bleed Risk Level"];
    var infectionlev =patientData["Infection Risk Level"];
    var bleedval = "";
    var infectval = "";

    var rect1, rect2, rect3, rect4;
   
    function getRecommendations() {
      if(infectionlev === 'High' && bleedlev === 'High') {
        rect1 = "#FFC413";
        rect2 = '#EDEDED';
        rect3 = '#FFC413';
        rect4 = '#EDEDED';
        bleedval = 'arista';
        infectval = 'infectionbundle';
      }
      else if(bleedlev === 'High' && infectionlev === 'Low') {
          rect1 = "#EDEDED";
          rect2 = '#F2C76D';
          rect3 = '#EDEDED';
          rect4 = '#2198F3';
          bleedval = 'hemostat';
          infectval = 'standard';
      }
      else if(bleedlev === 'Low' && infectionlev === 'High') {
          rect1 = "#EDEDED";
          rect2 = '#EDEDED';
          rect3 = '#FFC413';
          rect4 = '#EDEDED';
          bleedval = '';
          infectval = 'infectionbundle';

      }
      else {
          rect1 = "#EDEDED";
          rect2 = '#EDEDED';
          rect3 = '#EDEDED';
          rect4 = '#2198F3';
          bleedval = '';
          infectval = 'standard';


      }


    }


   

  return (
    
    <div style={{backgroundColor: 'white'}}>
      <div className='body'>
        <Navbar useType={props.useType}/>
        <Foldernav name={name} mrn={mrn} useType={props.useType}/>
        <div className='heading-font'>
          <span>Risk Predictions</span>
        </div>
        <div className='horizontal-kela'>
          <div className='bleedrisk'>
            <Kelacard title={"Bleeding Risk"} mrn={mrn} level={patientData["Bleed Risk Level"]} risk={patientData["Probability of Bleed Risk"]} relative={patientData["Bleed Risk Relative Risk"]} />
          </div>
          <div className='infectionrisk'>
          <Kelacard title={"Infection Risk"} mrn={mrn} level={patientData["Infection Risk Level"]} risk={patientData["Probability of Infection Risk"]} relative={patientData["Infection Risk Relative Risk"]} />
          </div>
        </div>
        <div className='heading-font'>
          <span>Recommendations</span>
        </div>
          <div className='rec'>
            {getRecommendations()}
            <Recommendation recs={patientData["Recommendation"]} bloodprep={patientData["Blood Preparation Recommendation"]} rect1={rect1} rect2={rect2} rect3={rect3} rect4={rect4} bleedval={bleedval} infectval={infectval}/>

          </div>
      </div>  
      <footer className='footer'></footer>
    </div>
  )
}

export default RiskPredictions