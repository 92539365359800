export function contributingRiskFactors(riskFactorJSON) {
  if (!riskFactorJSON) return;
  const optime = riskFactorJSON['OPERATION_TIME'];
  const discharge_disposition = riskFactorJSON['DISCHARGE_DISPOSITION'];

  return {
    optime: {
      laparoscopic: Object.entries(optime.Laparoscopic).map(([k, value]) => ({ key: k.split('_')[0], value })),
      open: Object.entries(optime.Open).map(([k, value]) => ({ key: k.split('_')[0], value })),
      robotic: Object.entries(optime.Robotic).map(([k, value]) => ({ key: k.split('_')[0], value })),
      all: Object.entries(optime.All || []).map(([k, value]) => ({ key: k.split('_')[0], value })),
    },
    discharge_disposition: {
      laparoscopic: Object.entries(discharge_disposition.Laparoscopic).map(([k, value]) => ({ key: k.split('_')[0], value })),
      open: Object.entries(discharge_disposition.Open).map(([k, value]) => ({ key: k.split('_')[0], value })),
      robotic: Object.entries(discharge_disposition.Robotic).map(([k, value]) => ({ key: k.split('_')[0], value })),
      all: Object.entries(discharge_disposition.All || []).map(([k, value]) => ({ key: k.split('_')[0], value })),
    }
  }
}