import { useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { BeatLoader } from 'react-spinners';
import logo from '../assets/logo-color.png';


const pdfDimensions = {
  logo: {
    x: 2,
    y: 2,
    width: 60,
    height: 10
  },
  0: {
    x: 35,
    y: 15,
    xOffset: -80,
    yOffset: -160
  },
  1: {
    x: 25,
    y: 5,
    xOffset: -40,
    yOffset: -60
  }
}

export default function({ imageRefs = [] }) {
  const [isDownloading, toggleDownloading] = useState(false);

  const handleDownloadPDF = async () => {
    toggleDownloading(true);
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();

    const { logo: logoDim } = pdfDimensions;

    pdf.addImage(logo, 'PNG', logoDim.x, logoDim.y, logoDim.width, logoDim.height);
    pdf.setFontSize(10);
    pdf.text(`Confidential`,  pdfWidth / 2 - 10, 8)
    pdf.text(`Created on: ${new Date().toISOString().split('T')[0]}`,  pdfWidth - 40, 8)

    await Promise.all(imageRefs.map(async (pdfRef, i) => {
      const dim = pdfDimensions[i];
      const element = pdfRef.current;
      const canvas = await html2canvas(element, {
        allowTaint: false,
        useCORS: true,
        scale: 2,
      });
      const data = canvas.toDataURL('image/png');
      const imgProperties = pdf.getImageProperties(data);
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(data, 'PNG', dim.x, dim.y, pdfWidth + dim.xOffset, pdfHeight + dim.yOffset);
      // pdf.addImage(data, 'PNG', 20, -5, pdfWidth - 30, pdfHeight - 85);
      if (i < imageRefs.length - 1) pdf.addPage();
    }));
    pdf.save('kelaHealth_report.pdf');
    toggleDownloading(false);
  };

  return (
    
    !isDownloading ? 
      <div onClick={handleDownloadPDF} style={styles.container}>
        <span>Download PDF</span>
      </div>
      : 
      <div onClick={handleDownloadPDF} style={styles.container}>
        <span style={{ display: 'flex', flex: 1 }}></span><BeatLoader size={10} color="white" />
      </div> 
  );
}

const styles = {
  container: {
    position: 'absolute',
    background: 'linear-gradient(90deg, rgba(4,100,180,1) 3%, rgba(16,140,235,1) 80%, rgba(36,156,244,1) 100%)',
    bottom: 20,
    right: 20,
    height: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxShadow: '2px 2px 2px grey',
    color: 'white', 
    fontWeight: 700,
    cursor: 'pointer',
    padding: '15px',
    paddingTop: '5px',
    paddingBottom: '6px',
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center'
  }
}
