import React, { Component } from 'react'
import './Foldernav.css'
import kelalogo from './../../images/kelalogo.png'
import { Link } from 'react-router-dom'

class Foldernav extends Component {
    render() {
        return (
        <div className='foldernav'>
            <div className='heading'>
                <img src={kelalogo} alt='Kela Health Logo'></img>
                    <nav className='FolderNav'>
                        <ul className='foldernav-menu'>
                            <li>
                                <Link to={{pathname: `/RiskPredictions/${this.props.useType}/${this.props.name}_${this.props.mrn}`, state : this.props.useType}}>Risk Prediction</Link>
                            </li>
                            <li>
                                <Link to={{pathname: `/RiskFactors/${this.props.useType}/${this.props.name}_${this.props.mrn}`, state : this.props.useType}}>Risk Factors</Link>                            
                            </li>
                            <li>
                                <a href="#" className='inactive-link'>Help</a>
                            </li>
                        </ul>
                    </nav>
                
            </div>
            <div className='name'>
                {this.props.name} ({this.props.mrn}) 
            </div>
            
        </div>
       
        )

    }

}

export default Foldernav
