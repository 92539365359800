const { REACT_APP_PW_MANTYH } = process.env;

const users = {
  'aah@kelahealth.com': {
    password: 'passwordPassword',
    name: 'Illinois Masonic Medical Center',
    identifier: 'HOSPITAL_2'
  },
};

export async function login({ email, password }) {
  // const user = users[email];
  // if (!user || user.password !== password) throw Error('Invalid Login');
  email = 'aah@kelahealth.com';
  localStorage.setItem("email", 'aah@kelahealth.com');
  localStorage.setItem("name", 'Illinois Masonic Medical Center');
  localStorage.setItem("identifier", 'HOSPITAL_2');
  return {
    email,
    name: 'Illinois Masonic Medical Center',
    identifier: 'HOSPITAL_2'
  }
}

export function getUser() {
  return {
    email:'aah@kelahealth.com',
    name: 'Illinois Masonic Medical Center',
    identifier: 'HOSPITAL_2'
  }
}

export function clearLocalStorage() {
  localStorage.clear();
}

export function logout() {
  clearLocalStorage();
}