import React, {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import logo from '../Images/logo_white.png' 
import { DotLoader } from 'react-spinners';

const UseCase = () => {
    const [value, setValue] = useState('BD_Demo');

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('is_logged_in');
        console.log(isLoggedIn);
        if (!isLoggedIn) throw Error('Unauthorized');
    })

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const navigate = useNavigate();

    const urls = {
        admin_rep: 'https://robotics.kelahealth.com',
        bd_demo: 'https://becton-dickinson-demo.herokuapp.com/',
    }

    const handleChoice = () => {
        if(value === 'Surgical_Command') {
           window.open('https://www.figma.com/proto/SGuFzp0GwQF2R9gbemWMfu/AAH?type=design&node-id=2165-559903&scaling=contain&page-id=79%3A86250&starting-point-node-id=2165%3A559903', '_blank')
        } else if (value === 'admin_report') {
           window.open('https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FSGuFzp0GwQF2R9gbemWMfu%2FAAH%3Ftype%3Ddesign%26node-id%3D2165-559903%26scaling%3Dcontain%26page-id%3D79%253A86250%26starting-point-node-id%3D2165%253A559903', '_blank')
        } else if (value === 'admin_report_mobile') {
           window.open('https://www.figma.com/proto/xJgiTITLyyNa2Yq3IhamEp/Product?node-id=1607%3A85445&sca[…]-down&page-id=1607%3A85444&starting-point-node-id=1607%3A85445', '_blank')
        } else window.open(`/${value}`, '_blank')
    }


    //To be implemented
    function DataLoading() {
        return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontWeight: 700, backgroundColor: 'white', paddingBottom: '50px'}}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{ fontSize: '24px', color: '#fcc414', marginBottom: '30px' }}>Generating your report</div>
                <DotLoader color="#fcc414" />
            </div>
        </div>
        )
    }


  return (
    <div style={styles.container}>
        <div style={styles.card}>
            <div style={styles.topBlueMargin}>
                <img src={logo} style={{ width: '250px', marginLeft: '22px', marginTop: '2rem'}}></img>
                <div style={{ color: 'white', fontStyle: 'italic', fontSize: '16px', paddingTop: '.3rem', marginLeft: '22px' }}>Demo Hub</div>
            </div>
            <div style={styles.welcome}>Welcome</div>
            <div style={styles.useCase}>Choose a Use Case</div>
            <div style={styles.dropdown}>
                <select style={{ width: '90%', fontSize: '16px', padding: '8px', borderColor: '#CED4DA', borderRadius: '4px', height: '42px'}} onChange={handleChange}>
                    <optgroup style={{color: '#707070', fontWeight: '500'}} label='Clinical Quality'>
                        <option value="BD_Demo" style={{color: 'black', fontWeight: '500' }}>Bleeding and Infection</option>
                        <option value="/" style={{color: 'black', fontWeight: '500'}}>Cardiac</option>
                        <option value="/" style={{color: 'black', fontWeight: '500'}}>Neurologic</option>
                        <option value="/" style={{color: 'black', fontWeight: '500'}}>Renal</option>
                        <option value="/" style={{color: 'black', fontWeight: '500'}}>Respiratory</option>
                        <option value="/" style={{color: 'black', fontWeight: '500'}}>Sepsis</option>
                        <option value="/" style={{color: 'black', fontWeight: '500'}}>Thrombotic</option>
                        <option value="/" style={{color: 'black', fontWeight: '500'}}>Urinary Tract Infection</option>
                        <option value="/" style={{color: 'black', fontWeight: '500'}}>Wound</option>
                    </optgroup>
                    <optgroup  style={{color: '#707070', fontWeight: '500'}} label='Clinical Operations'>
                        <option value="Surgical_Command" style={{color: 'black', fontWeight: '500'}}>Surgical Command Center</option>
                    </optgroup>
                    <optgroup  style={{color: '#707070', fontWeight: '500'}} label='Reporting'>
                        <option value="admin_report" style={{color: 'black', fontWeight: '500'}}>Administrative Report</option>
                        <option value="admin_report_mobile" style={{color: 'black', fontWeight: '500'}}>Administrative Report (Mobile)</option>
                        <option value="/" style={{color: 'black', fontWeight: '500'}}>Surgeon Report</option>
                    </optgroup>
                </select>
            </div>
            <div style={{ display: 'flex', width: '319px', alignSelf: 'center', padding:'30px'}}>
                <button onClick={handleChoice} style={styles.button}>View Solution</button>

            </div>

            
                
        </div>
    </div>
  )
}
export default UseCase

const styles = {
    container: {
      display: 'flex',
      flex: 1,
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#EBEBEB',
      font: 'Roboto',
    },
    topBlueMargin: { 
        display: 'flex', 
        flexDirection: 'column', 
        backgroundColor: '#0367B4',
        height: '106px',
        justifyContent: 'center', 
        alignItems: 'flex-start',
        borderRadiusTop: '10px',
        paddingBottom: '30px',
        width: '503px'
    },
    card: { 
      display: 'flex',
      flexDirection: 'column', 
      backgroundColor: 'white',
      //background: 'linear-gradient(90deg, rgba(4,100,180,1) 3%, rgba(16,140,235,1) 80%, rgba(36,156,244,1) 100%)', 
      width: '503px', 
      height: '500px',
      boxShadow: '5px 5px 5px grey',
      borderRadius: '8px'
    },
    dropdown: {
      width: '95%',
      marginTop: '30px',
      marginBottom: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputLable: {
      display: 'block',
      color: '#333333',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '700',
      paddingTop: '.5rem',
      paddingBottom: '.5rem'
    },
    button: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#0367B4',
      color: 'white',
      fontSize: '16px',
      padding: '10px 60px',
      borderRadius: '4.8px',
      margin: '10px 0px',
      cursor: 'pointer',
      width: '319px',
      border: 'none',
    },
    welcome: {
        fontWeight: '700',
        fontSize: '36px',
        color: '#333333',
        paddingLeft: '25px',
        paddingTop: '25px',
        paddingBottom: '8px'
    },
    useCase: {
        fontWeight: '700',
        fontSize: '20px',
        color: '#505050',
        paddingLeft: '25px'
    }
  }