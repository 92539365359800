import React, { Component } from 'react'
import { MenuItems } from './MenuItems'
import './Navbar.css'
import ehr from './../../images/EHR-1_ai.ai.png'
import { useNavigate, NavLink } from 'react-router-dom'
import { isCursorAtEnd } from '@testing-library/user-event/dist/utils'
import { useAuth } from '../../useAuth'


const Navbar = ({useType}) => {

    //state = { clicked: false }
    // var drName;
    // if(useType === "General_Surgery") {
    //     drName = "LEE";
    // }
    // else if(useType === "Gynecology") {
    //     drName = "KHAN";
    // }
    // else {
    //     drName = "HERNANDEZ";
    // }

    var useTypeMod = "/" + useType;
    let navigate = useNavigate();
    const auth = useAuth();
/*
    handleClick = () => {
        this.setState({clicked: !this.state.clicked})
    }
    */

    const handleLogoClick = event => {
        navigate('/UseCase')
      console.log('logout');
    }

    return(
        <div>
            
            <nav className='NavbarItems'>
                <div className='navbar-logo'>
                <div>
                    <img src={ehr} alt='EHR Logo' onClick={handleLogoClick}></img>
                </div>
                    
                </div>
                <div className='welcome-text'>
                    WELCOME!
                </div>
                <ul className='nav-menu'>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <div className='menu-item' onClick={() => navigate(useTypeMod, {state:{name: useType}})} style={{color: 'white', cursor: 'pointer'}}>
                                    <div className='image-format'>{item.image}</div>
                                    {item.title}
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </nav>

        </div>
        
    ) 
    

}

export default Navbar