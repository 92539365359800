import React from 'react';
import './App.css'
import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Login from './Pages/Login.js'
import UseCase from './Pages/UseCase.js';
import BD_Demo from '../src/Demos/bd/src/App.js'
import AAH_Demo from '../src/Demos/aah/src/App.js'

import Usage from '../src/Demos/bd/src/pages/Usage';
import Surgeries from '../src/Demos/bd/src/pages/Surgeries';
import RiskPredictions from '../src/Demos/bd/src/pages/RiskPredictions';
import RiskFactors from '../src/Demos/bd/src/pages/RiskFactors';


function App() {
  return (
    <Router>
        <Routes>
              <Route path="/" element={<Login />}></Route>
              <Route path="/UseCase" element={<UseCase />}/>

              {/* BD DEMO */}
              <Route path="/BD_Demo" element = {<BD_Demo />} target="_blank"/>
              <Route path="/Usage" element={<Usage />}/>
              <Route path="/General_Surgery" element={<Surgeries />}/>
              <Route path="/Gynecology" element={<Surgeries />}/>
              <Route path="/Orthopedics" element={<Surgeries />}/>
              <Route path="/RiskPredictions/General_Surgery/:patientnamemrn" element={<RiskPredictions useType="General_Surgery"/>}/>
              <Route path="/RiskPredictions/Gynecology/:patientnamemrn" element={<RiskPredictions useType="Gynecology" />}/>
              <Route path="/RiskPredictions/Orthopedics/:patientnamemrn" element={<RiskPredictions useType="Orthopedics"/>}/>
              <Route path="/RiskFactors/General_Surgery/:patientnamemrn" element={<RiskFactors useType="General_Surgery"/>}/>
              <Route path="/RiskFactors/Gynecology/:patientnamemrn" element={<RiskFactors useType="Gynecology" />}/>
              <Route path="/RiskFactors/Orthopedics/:patientnamemrn" element={<RiskFactors useType="Orthopedics"/>}/>

              {/* AAH DEMO */}
              <Route path="/AAH_Demo" element = {<AAH_Demo />}/>

        </Routes>   
    </Router>
  );
}

export default App;
