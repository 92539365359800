import React from 'react'
import Chart from 'react-apexcharts'
import Plot from 'react-plotly.js';
import './FactorChart.css'


const FactorChart = (props) => {
    let negWeights2 = [];
    let negAttributes2 = [];
    let posWeights2 = [];
    let posAttributes2 = [];
    let negOrigValMap = new Map();
    let negOrigVal = [];
    let posOrigValMap = new Map();
    let posOrigVal = [];

    function getHoverLabels() {
        for(let i = 0; i < 6; i++) {
            posOrigVal[i] = posOrigValMap.get(posAttributes2[i]);
            negOrigVal[i] = negOrigValMap.get(negAttributes2[i]);
        }
        
    }
    
    
    function tryMap() {
        try {
            
            let posData = props.chartData["Increasing Risk"];
            let negData = props.chartData["Decreasing Risk"];
            let negMap = new Map();
            let posMap = new Map();

            Object.entries(negData).map(([item, weight]) => {
                negMap.set(item, weight["Weight"]);
                negOrigValMap.set(item, weight["Original Value"]);
                negMap = new Map([...negMap].sort((a, b) => a[1] - b[1]));
                let tempNegAtt = [...negMap.keys()]
                let tempNegWeights = [...negMap.values()]
                for(let i = 0; i < 6; i++) {
                    negAttributes2[i] = tempNegAtt[i];
                    negWeights2[i] = tempNegWeights[i];
                }
                return (
                    
                    
                    <p> I'm Taylor </p>
                );
            });

            Object.entries(posData).map(([item, weight]) => {
                posMap.set(item, weight["Weight"]);
                posMap = new Map([...posMap].sort((a, b) => b[1] - a[1]));
                posOrigValMap.set(item, weight["Original Value"]);
                let tempPosAtt = [...posMap.keys()]
                let tempPosWeights = [...posMap.values()]
                for(let i = 0; i < 6; i++) {
                    posAttributes2[i] = tempPosAtt[i];
                    posWeights2[i] = tempPosWeights[i];
                }
                return (
                    <p> I'm Taylor </p>
                );
            });
        }
        catch(err) {
            console.log(err);
        }
        

    }

    var title;

 
    var bleedinfData;
    var riskfactordata;


    var data;

    var topSixWeightsInc = [];
    var topSixRisksInc = [];
    var ogValMapInc = new Map();
    var weightMapInc = new Map();
    let weightsArrayInc = [];
    var factArrayInc = [];

    var topSixWeightsDec = [];
    var topSixRisksDec = [];
    var ogValMapDec = new Map();
    var weightMapDec = new Map();
    let weightsArrayDec = [];
    var factArrayDec = [];
    
    function getData() {

        bleedinfData = props.chartData[props.bleedorinf];
        if(bleedinfData && props.risks === 'All') {
            var increasedata = bleedinfData["Increasing Risk"];
            Object.entries(increasedata).map(([item, weight]) => {
                weightMapInc.set(item, weight["Weight"]);
                ogValMapInc.set(item, weight["Original Value"]);
                weightMapInc = new Map([...weightMapInc].sort((a, b) => b[1] - a[1]));
                factArrayInc = [...weightMapInc.keys()]
                weightsArrayInc = [...weightMapInc.values()];
            });
            
    
            for(var i = 0; i < 6; i++) {
                topSixRisksInc[i] = factArrayInc[6-i] + " : " + ogValMapInc.get(factArrayInc[6-i]);
                topSixWeightsInc[i] = weightsArrayInc[6-i];
            }

            var decreasedata = bleedinfData["Decreasing Risk"];
            Object.entries(decreasedata).map(([item, weight]) => {
                weightMapDec.set(item, weight["Weight"]);
                ogValMapDec.set(item, weight["Original Value"]);
                weightMapDec = new Map([...weightMapDec].sort((a, b) => a[1] - b[1]));
                factArrayDec = [...weightMapDec.keys()]
                weightsArrayDec = [...weightMapDec.values()];
            });
            
    
            for(var i = 0; i < 6; i++) {
                topSixRisksDec[i] = factArrayDec[6-i] + " : " + ogValMapDec.get(factArrayDec[6-i]);
                topSixWeightsDec[i] = weightsArrayDec[6-i];
            }


            //Get title 
            if(props.bleedorinf === "Bleed Risk Factors") {
                title = "All Risk Factor Contribution for Bleeding Risk"
            }
            else {
                title = "All Risk Factor Contribution for Infection Risk"

            }

            data = [
                {
                    name: "Decreases Risk",
                    x: topSixWeightsDec,
                    y: topSixRisksDec,
                    type: 'bar',
                    orientation: 'h',
                    marker: {color: "rgba(74, 142, 195, 1)"}
                },
                {
                    name: "Increases Risk",
                    x: topSixWeightsInc,
                    y: topSixRisksInc,
                    type: 'bar',
                    orientation: 'h',
                    marker: {color: "rgba(255, 154, 19, 1)"}
                },
                
                
                
              ];
            

        }
        else if(bleedinfData) {
            riskfactordata = bleedinfData[props.risks];
            mapIndividual();
        }


        
    }

    var topSixWeights = [];
    var topSixRisks = [];
    var ogValMap = new Map();
    var weightMap = new Map();
    let weightsArray = [];
    var factArray = [];

    var barcolor;
    var name;

    function mapIndividual() {
        Object.entries(riskfactordata).map(([item, weight]) => {
            weightMap.set(item, weight["Weight"]);
            ogValMap.set(item, weight["Original Value"]);
            if(props.risks === "Increasing Risk") {
                name = "Increases Risk";
                barcolor = "rgba(255, 154, 19, 1)";
                weightMap = new Map([...weightMap].sort((a, b) => b[1] - a[1]));
            }
            else {
                name = "Decreases Risk";
                barcolor = "rgba(74, 142, 195, 1)";
                weightMap = new Map([...weightMap].sort((a, b) => a[1] - b[1]));
            }
            factArray = [...weightMap.keys()]
            weightsArray = [...weightMap.values()];
        });
        

        //Get top six of both
        for(var i = 0; i < 6; i++) {
            topSixRisks[i] = factArray[6-i] + " : " + ogValMap.get(factArray[6-i]);
            topSixWeights[i] = weightsArray[6-i];
        }

        //Get title
        if(props.bleedorinf === "Bleed Risk Factors" && props.risks === "Increasing Risk") {
            title = "Increasing Risk Factor Contribution for Bleeding Risk"
        }
        else if(props.bleedorinf === "Bleed Risk Factors" && props.risks === "Decreasing Risk") {
            title = "Decreasing Risk Factor Contribution for Bleeding Risk"
        }
        else if(props.bleedorinf === "Infection Risk Factors" && props.risks === "Decreasing Risk") {
            title = "Decreasing Risk Factor Contribution for Infection Risk"
        }
        else if(props.bleedorinf === "Infection Risk Factors" && props.risks === "Increasing Risk") {
            title = "Incresaing Risk Factor Contribution for Infection Risk"
        }

        data = [
            {
                name: name,
                x: topSixWeights,
                y: topSixRisks,
                type: 'bar',
                orientation: 'h',
                marker: {color: barcolor}
            },
            
          ];
    }
            


    
  return (
    
    <div className='chart-container'>
        {getData()}
        <Plot
        data={data}
        layout={{width: 600, height: 500, hovermode:false, title: title, font: {
            family: 'Roboto, sans-serif',
            color: '#000000'
          },  xaxis: {
            automargin: true,
            },
          yaxis: {
            automargin: true,
            }, showlegend:true, margin:{pad: 20},}}
            
      />
        
    
    </div>
        
        

  )
}


export default FactorChart