import React, {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import ehr from '../images/EHR-1_ai.ai.png'
import './Usage.css'

const Usage = () => {

    
    const [value, setValue] = useState("/General_Surgery", {state:{name:'General_Surgery'}});
    let navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('is_logged_in');
        if (!isLoggedIn) throw Error('Unauthorized');
    },[]);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    var submitname = "";
    const handleSubmit = () => {
        if(value === "/General_Surgery") {
            submitname = "General_Surgery";
        }
        else if (value === "/Orthopedics"){
            submitname = "Orthopedics";
        }
        else if (value === "/Gynecology") {
            submitname = "Gynecology";
        }

        navigate(value, {state:{name:submitname}});

    };


  return (
    <div className='background'>
        <div className='main-container'>
            <div className='image'>            
                <img src={ehr} style={{background: '#0367B4', order:'0', display:'flex'}}></img>  
            </div>
            <div className='dropdown-welcome'>
                <h1>Welcome</h1>
                <h2>Choose a Service</h2>
            </div>
            <div className='dropdown'>
                <select onChange={handleChange}>
                    <option value="/General_Surgery">General Surgery</option>
                    <option value="/Gynecology">Ob/Gyn</option>
                    <option value="/Orthopedics">Orthopedics</option>
                </select>

                <div className='submit'>
                    <input type="button" value="Sign In" style={{background:' #0367B4', color:'white', height:'40px', cursor: 'pointer'}} onClick={handleSubmit}></input>
                </div>
    
            </div>
            
            
            

            
            
        </div>
    </div>
  )
}

export default Usage